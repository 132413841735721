var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.packages !== null)?_c('div',[(_vm.packages.length > 0)?_c('section',{staticClass:"secction-h tour-section-1 mt-5"},[_c('b-container',{staticClass:"bv-section1-row pt-5"},[_c('b-row',{staticClass:"px-3 px-lg-0"},[(_vm.localeTitle == 'en')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.enTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'cn')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.cnTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'hk')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.hkTitle)+" ")]):_vm._e()]),(_vm.area.featuredVideoUrl)?_c('b-row',[(_vm.area.featuredVideoUrl)?_c('video',{staticClass:"w-100 px-3 px-lg-0",attrs:{"width":"1140","height":"640px","controls":"","autoplay":_vm.area.autoplay === 'Y' ? true : false,"loop":_vm.area.loopFlag === 'Y' ? true : false},domProps:{"muted":_vm.area.autoplay === 'Y' ? true : false}},[_c('source',{attrs:{"src":_vm.oss + _vm.area.featuredVideoUrl}}),_vm._v(" "+_vm._s(_vm.$t('header.browser_msg'))+" ")]):_vm._e()]):_vm._e(),_c('b-row',{staticClass:"mt-4"},[(_vm.packages[0])?_c('b-col',{staticClass:"px-3 px-lg-0",attrs:{"sm":"12","lg":"8"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[0].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white text-center rgba-black-strong py-3 px-2"},[_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-2"},[_vm._v(" ("+_vm._s(_vm.packages[0].packageCode)+") "+_vm._s(_vm.packages[0].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 180,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 180,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" "+_vm._s(_vm.packages[0].shortDescription)+" ")])])])]),(_vm.packages[0].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[0].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])],1):_vm._e(),(_vm.packages[1])?_c('b-col',{staticClass:"px-3 px-lg-0",class:[_vm.packages[0] ? 'pl-lg-2' : ''],attrs:{"sm":"12","lg":"4"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[1].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white rgba-black-strong py-1 px-3 py-2"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-left b-item",attrs:{"cols":"7"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-1"},[_vm._v(" ("+_vm._s(_vm.packages[1].packageCode)+") "+_vm._s(_vm.packages[1].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                            height: 180,
                            character: '...',
                            spaces: false,
                          }),expression:"{\n                            height: 180,\n                            character: '...',\n                            spaces: false,\n                          }"}]},[_vm._v(" "+_vm._s(_vm.packages[1].shortDescription)+" ")])])])]),(_vm.packages[1].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1 b-item",attrs:{"cols":"5"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[1].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])])],1):_vm._e()],1),_c('b-row',[(_vm.packages[2])?_c('b-col',{staticClass:"px-3 px-lg-0",attrs:{"sm":"12","lg":"6"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[2].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white rgba-black-strong py-1 px-3 py-2"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-left b-item",attrs:{"cols":"8"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-1"},[_vm._v(" ("+_vm._s(_vm.packages[2].packageCode)+") "+_vm._s(_vm.packages[2].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                            height: 180,
                            character: '...',
                            spaces: false,
                          }),expression:"{\n                            height: 180,\n                            character: '...',\n                            spaces: false,\n                          }"}]},[_vm._v(" "+_vm._s(_vm.packages[2].shortDescription)+" ")])])])]),(_vm.packages[2].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1 b-item",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[2].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])])],1):_vm._e(),(_vm.packages[3])?_c('b-col',{staticClass:"px-3 px-lg-0",class:[_vm.packages[2] ? 'pl-lg-2' : ''],attrs:{"sm":"12","lg":"6"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[3].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white rgba-black-strong py-1 px-3 py-2"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-left b-item",attrs:{"cols":"8"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-1"},[_vm._v(" ("+_vm._s(_vm.packages[3].packageCode)+") "+_vm._s(_vm.packages[3].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                            height: 180,
                            character: '...',
                            spaces: false,
                          }),expression:"{\n                            height: 180,\n                            character: '...',\n                            spaces: false,\n                          }"}]},[_vm._v(" "+_vm._s(_vm.packages[3].shortDescription)+" ")])])])]),(_vm.packages[3].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1 b-item",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[3].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])])],1):_vm._e(),(_vm.packages[4])?_c('b-col',{staticClass:"px-3 px-lg-0",attrs:{"sm":"12","lg":"6"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[4].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white rgba-black-strong py-1 px-3 py-2"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-left b-item",attrs:{"cols":"8"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-1"},[_vm._v(" ("+_vm._s(_vm.packages[4].packageCode)+") "+_vm._s(_vm.packages[4].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                            height: 180,
                            character: '...',
                            spaces: false,
                          }),expression:"{\n                            height: 180,\n                            character: '...',\n                            spaces: false,\n                          }"}]},[_vm._v(" "+_vm._s(_vm.packages[4].shortDescription)+" ")])])])]),(_vm.packages[4].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1 b-item",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[4].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])])],1):_vm._e(),(_vm.packages[5])?_c('b-col',{staticClass:"px-3 px-lg-0",class:[_vm.packages[5] ? 'pl-lg-2' : ''],attrs:{"sm":"12","lg":"6"}},[_c('b-card',{staticClass:"card-image mb-3",style:({
              backgroundImage: `url("${
                _vm.oss + _vm.packages[5].mainPicture
              }")`,
            })},[_c('div',{staticClass:"text-white rgba-black-strong py-1 px-3 py-2"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-left b-item",attrs:{"cols":"8"}},[_c('div',{staticClass:"b-title"},[_c('h5',{staticClass:"py-1"},[_vm._v(" ("+_vm._s(_vm.packages[5].packageCode)+") "+_vm._s(_vm.packages[5].title)+" ")]),_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                            height: 180,
                            character: '...',
                            spaces: false,
                          }),expression:"{\n                            height: 180,\n                            character: '...',\n                            spaces: false,\n                          }"}]},[_vm._v(" "+_vm._s(_vm.packages[5].shortDescription)+" ")])])])]),(_vm.packages[5].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1 b-item",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[5].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])])],1):_vm._e()],1),_c('b-row',[(_vm.packages.length > 6)?_c('b-col',{staticClass:"text-center my-3"},[_c('a',{attrs:{"href":`#/packages/section1`}},[_c('b-button',{staticClass:"view-more",attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('More')))])],1)]):_vm._e()],1)],1)],1):_vm._e()]):_c('div',{staticStyle:{"height":"700px"}},[_c('section',{staticClass:"secction-h tour-section-1 mt-5 mb-3"},[_c('b-container',{staticClass:"bv-section1-row pt-5"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-card',{staticClass:"m-1",attrs:{"no-body":"","img-bottom":""}},[_c('b-card-body',[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('b-skeleton',{attrs:{"type":"button"}})],1)],1),_c('b-skeleton-img',{attrs:{"card-img":"bottom","aspect":"3:1"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-card',{staticClass:"m-1",attrs:{"no-body":"","img-bottom":""}},[_c('b-card-body',[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('b-skeleton',{attrs:{"type":"button"}})],1)],1),_c('b-skeleton-img',{attrs:{"card-img":"bottom","aspect":"1.5"}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }