<template>
  <div v-if="packages !== null">
    <section class="secction-h tour-section-1 mt-5" v-if="packages.length > 0">
      <b-container class="bv-section1-row pt-5">
        <b-row class="px-3 px-lg-0">
          <h3 v-if="localeTitle == 'en'">
            {{ area.enTitle }}
          </h3>
          <h3 v-if="localeTitle == 'cn'">
            {{ area.cnTitle }}
          </h3>
          <h3 v-if="localeTitle == 'hk'">
            {{ area.hkTitle }}
          </h3>
        </b-row>
        <b-row v-if="area.featuredVideoUrl">
          <video
            width="1140"
            height="640px"
            controls
            :autoplay="area.autoplay === 'Y' ? true : false"
            :muted="area.autoplay === 'Y' ? true : false"
            :loop="area.loopFlag === 'Y' ? true : false"
            class="w-100 px-3 px-lg-0"
            v-if="area.featuredVideoUrl"
          >
            <source :src="oss + area.featuredVideoUrl" />
            {{ $t('header.browser_msg') }}
          </video>
        </b-row>
        <b-row class="mt-4">
          <b-col sm="12" lg="8" class="px-3 px-lg-0" v-if="packages[0]">
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[0].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white text-center rgba-black-strong py-3 px-2">
                <b-row>
                  <b-col cols="6" class="text-left">
                    <div class="b-title">
                      <h5 class="py-2">
                        ({{ packages[0].packageCode }}) {{ packages[0].title }}
                      </h5>
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 180,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          {{ packages[0].shortDescription }}
                        </span>
                      </h6>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right align-bottom pb-1"
                    v-if="packages[0].link == 'Y'"
                  >
                    <router-link :to="`/tour/${packages[0].packageCode}`">
                      <b-button squared variant="outline-secondary">{{
                        $t('View_details')
                      }}</b-button>
                    </router-link>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col
            sm="12"
            lg="4"
            class="px-3 px-lg-0"
            :class="[packages[0] ? 'pl-lg-2' : '']"
            v-if="packages[1]"
          >
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[1].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white rgba-black-strong py-1 px-3 py-2">
                <div>
                  <b-row>
                    <b-col cols="7" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-1">
                          ({{ packages[1].packageCode }})
                          {{ packages[1].title }}
                        </h5>
                        <h6 class="purple-text">
                          <span
                            v-shave="{
                              height: 180,
                              character: '...',
                              spaces: false,
                            }"
                          >
                            {{ packages[1].shortDescription }}
                          </span>
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="5"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[1].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[1].packageCode}`">
                        <b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button>
                      </router-link>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" lg="6" class="px-3 px-lg-0" v-if="packages[2]">
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[2].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white rgba-black-strong py-1 px-3 py-2">
                <div>
                  <b-row>
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-1">
                          ({{ packages[2].packageCode }})
                          {{ packages[2].title }}
                        </h5>
                        <h6 class="purple-text">
                          <span
                            v-shave="{
                              height: 180,
                              character: '...',
                              spaces: false,
                            }"
                          >
                            {{ packages[2].shortDescription }}
                          </span>
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[2].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[2].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col
            sm="12"
            lg="6"
            class="px-3 px-lg-0"
            :class="[packages[2] ? 'pl-lg-2' : '']"
            v-if="packages[3]"
          >
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[3].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white rgba-black-strong py-1 px-3 py-2">
                <div>
                  <b-row>
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-1">
                          ({{ packages[3].packageCode }})
                          {{ packages[3].title }}
                        </h5>
                        <h6 class="purple-text">
                          <span
                            v-shave="{
                              height: 180,
                              character: '...',
                              spaces: false,
                            }"
                          >
                            {{ packages[3].shortDescription }}
                          </span>
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[3].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[3].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" lg="6" class="px-3 px-lg-0" v-if="packages[4]">
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[4].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white rgba-black-strong py-1 px-3 py-2">
                <div>
                  <b-row>
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-1">
                          ({{ packages[4].packageCode }})
                          {{ packages[4].title }}
                        </h5>
                        <h6 class="purple-text">
                          <span
                            v-shave="{
                              height: 180,
                              character: '...',
                              spaces: false,
                            }"
                          >
                            {{ packages[4].shortDescription }}
                          </span>
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[4].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[4].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col
            sm="12"
            lg="6"
            class="px-3 px-lg-0"
            :class="[packages[5] ? 'pl-lg-2' : '']"
            v-if="packages[5]"
          >
            <b-card
              class="card-image mb-3"
              :style="{
                backgroundImage: `url(&quot;${
                  oss + packages[5].mainPicture
                }&quot;)`,
              }"
            >
              <div class="text-white rgba-black-strong py-1 px-3 py-2">
                <div>
                  <b-row>
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-1">
                          ({{ packages[5].packageCode }})
                          {{ packages[5].title }}
                        </h5>
                        <h6 class="purple-text">
                          <span
                            v-shave="{
                              height: 180,
                              character: '...',
                              spaces: false,
                            }"
                          >
                            {{ packages[5].shortDescription }}
                          </span>
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[5].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[5].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center my-3" v-if="packages.length > 6">
            <a :href="`#/packages/section1`"
              ><b-button
                class="view-more"
                squared
                variant="outline-secondary"
                >{{ $t('More') }}</b-button
              ></a
            >
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
  <div v-else style="height: 700px">
    <section class="secction-h tour-section-1 mt-5 mb-3">
      <b-container class="bv-section1-row pt-5">
        <b-row>
          <b-col cols="12" md="8">
            <b-card no-body img-bottom class="m-1">
              <b-card-body>
                <b-skeleton width="60%"></b-skeleton>
                <div class="d-flex justify-content-between">
                  <b-skeleton width="60%"></b-skeleton>
                  <b-skeleton type="button"></b-skeleton>
                </div>
              </b-card-body>
              <b-skeleton-img card-img="bottom" aspect="3:1"></b-skeleton-img>
            </b-card>
          </b-col>
          <b-col cols="12" md="4">
            <b-card no-body img-bottom class="m-1">
              <b-card-body>
                <b-skeleton width="60%"></b-skeleton>
                <div class="d-flex justify-content-between">
                  <b-skeleton width="60%"></b-skeleton>
                  <b-skeleton type="button"></b-skeleton>
                </div>
              </b-card-body>
              <b-skeleton-img card-img="bottom" aspect="1.5"></b-skeleton-img>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import Vue from 'vue';
import configUrl from '@/global/config.js';
export const lang = new Vue();
export default {
  name: 'section1',
  data() {
    return {
      // packages: null,
      area: null,
      loading: true,
      errored: false,
      slug: null,
      localeTitle: 'en',
      oss: '',
    };
  },
  props: {
    area: {
      type: Array,
    },
  },
  created() {
  },
  computed:{
    packages(){
      return this.area&&this.area.packagesVos||[]
    }
  },
  mounted() {
    // this.packages = this.area.packagesVos;
    // this.slug = this.area.slug;
    this.oss = configUrl.oss_path;

    if (localStorage.getItem('lang_frontend') == 'en') {
      this.localeTitle = 'en';
    } else if (localStorage.getItem('lang_frontend') == 'cn') {
      this.localeTitle = 'cn';
    } else {
      this.localeTitle = 'hk';
    }
  },
};
</script>
<style type="text/css">
.tour-section-1 .b-item {
  padding-right: 12px;
  padding-left: 12px;
}
.tour-section-1 .col:nth-child(1) {
  padding-right: 5px;
}
.tour-section-1 .col:nth-child(2) {
  padding-left: 5px;
}
.tour-section-1 .col:nth-child(3) {
  padding-left: 5px;
}
.tour-section-1 {
  margin-top: 5rem !important;
}
.align-bottom {
  align-self: flex-end;
}
.tour-section-1 .card {
  background-repeat: no-repeat;
  /*min-height: 22rem;*/
  height: 22rem;
  background-size: cover;
  border-radius: unset;
  flex-direction: column-reverse;
}
.tour-section-1 .card h5,
.tour-section-1 .card h6 {
  font-weight: 400;
}
.secction-h button {
  background-color: #f6a604;
  padding: 0.3rem 1rem;
  border-radius: 4px !important;
  border: 1px solid #f6a604;
}
.area-sub-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  color: #666666;
  opacity: 1;
  margin-left: 0.5rem;
}
button.view-more {
  background-color: unset;
  color: #f6a604;
  padding: 0.5rem 2rem;
}
.b-title {
  color: white;
  text-shadow: -1px -1px 0 #ff7e09, 1px -1px 0 #ff7e09, -1px 1px 0 #ff7e09,
    1px 1px 0 #ff7e09;
  /*font-size: 1rem;*/
  /*padding: 3px 7px;
    background-color: #070919bf;
    opacity: 0.9;*/
}
button.view-more:hover {
  border: 1px solid #f6a604;
}
</style>
